import moment from "moment-timezone";

export default {
    namespaced: true,
    state: {},
    getters: {},
    actions: {
        stopAllAudioVideo() {
            if (document.querySelector('video')) {
                const video = document.querySelector('video');
                const mediaStream = video.srcObject;
                const tracks = mediaStream.getTracks();
                tracks[0].stop();
                tracks.forEach(track => track.stop())
            }
        },
        eventTimeU(_, {dst, det}) {
            return new Promise((resolve) => {
                let ndt = '';
                let day = moment(dst).format("MMMM Do YYYY");
                let startTime = moment(dst).format("h:mm a");
                let endTime = moment(det).format("h:mm a");
                ndt = day + ' / ' + startTime + ' - ' + endTime;
                resolve(ndt)
            })
        },
    },
    mutations: {}
}