<template>
  <div>
    <p class="bg-success white text-center" v-show="msg">
      {{ msg }}
    </p>
    <div class="row mt-3 mb-3">
      <div class="col-lg-4 col-md-4">

      </div>
      <div class="col-lg-4 col-md-4">
        <label for="signup-password">Create New Password</label>
        <input v-model="form.password" @blur="v$.form.password.$touch"
               :class="{'border border-danger': v$.form.password.$error}" type="password" class="form-control"
               id="signup-password">
        <p class="alert alert-danger p-1 mt-2" role="alert" v-if="v$.form.password.$error">Enter a password with 5
          characters or more.</p>
      </div>
    </div>
    <div class="row mt-3 mb-3">
      <div class="col-lg-4 col-md-4">

      </div>
      <div class="col-lg-4 col-md-4">
        <label for="signup-password-confirm">Confirm New Password</label>
        <input v-model="form.passwordConfirm" @blur="v$.form.passwordConfirm.$touch" type="password"
               :class="{'border border-danger': v$.form.passwordConfirm.$error}" class="form-control"
               id="signup-password-confirm">
        <p class="alert alert-danger p-1 mt-2" role="alert" v-if="v$.form.passwordConfirm.$error">Please make sure
          passwords match.</p>
      </div>
    </div>
    <div class="row mt-3 mb-3">
      <div class="col-lg-4 col-md-4">
      </div>
      <div class="col-lg-4 col-md-4">
        <button class="btn btn-primary" @click="submit">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required, sameAs, minLength} from '@vuelidate/validators'

export default {
  name: "forgotReset",
  props: ['forgotid'],
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      fullPage: false,
      msg: '',
      form: {
        password: '',
        passwordConfirm: ''
      }
    }
  },
  validations() {
    return {
      form: {
        password: {required, min: minLength(5)},
        passwordConfirm: {required, sameAsPassword: sameAs(this.form.password)},
      }
    }
  },
  methods: {
    submit() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      this.$store.dispatch('userAccess/resetPassword', {forgotId: this.forgotid, pass: this.form.password})
          .then(res => {
            if(res.pass){
              this.$router.push({name: 'Login', params: {msg:'Password Reset'}})
            }
            loader.hide()
          })
          .catch(error => {
            console.log('🤷‍️' + error.message)
            loader.hide()
          })
    },
    onCancel() {
      console.log('User cancelled the loader.')
    }
  },
  mounted() {
    this.$store.dispatch('userAccess/forgotEmailCheck', this.forgotid)
        .then(res => {
          if(!res.idok){
            this.$router.push({name: 'Login', params: {msg:'Link already used'}})
          }
        })
        .catch(() => {
          this.loading = false
          // alert('🤷‍️' + error.message)
        })
  }
}
</script>

<style scoped>

</style>