<template>
  <div>
    <div class="bg-lyellow" v-show="msg">{{ msg }}</div>
    <div v-if="eventInfo">
      {{ eventInfo.name }} {{ eventInfo.address }}, {{ eventInfo.address_city }} {{ eventInfo.address_state }}
      {{ eventInfo.address_postalcode }}
      <div id="reader" class="w-100"></div>
    </div>
    <audio id="sound1" :src="scansound"></audio>
    <div class="container-fluid">
      <div class="div row">
        <div class="col-5 h4 bg-yellow ">Guest</div>
        <div class="col-7 h4 bg-yellow ">Date</div>
      </div>
      <div class="row border border-1" v-for="user in usersCheckedIn" :key="user.dateattended">
        <div class="col-5">{{user.name}}</div>
        <div class="col-7">{{user.dateattended}}</div>
      </div>
    </div>
  </div>
</template>

<script>
//import {Html5QrcodeScanner, Html5QrcodeScanType} from "html5-qrcode"
import {Html5Qrcode} from "html5-qrcode"
import {mapGetters} from "vuex";
//import moment from "moment-timezone";

export default {
  name: "CheckInUser",
  data() {
    return {
      html5QrCode: '',
      eventInfo: {},
      scansound: require('@/assets/sounds/taco-crunch.mp3'),
      msg: ''
    }
  },
  methods: {
    beep() {
      var sound = document.getElementById('sound1');
      sound.play();
    },
    HasEvent() {
      let dates = []
      let keys = []
      for (const [key, value] of Object.entries(this.listEvents)) {
        keys.push(key)
        dates.push(value)
      }

      let newDates = dates.sort(function (a, b) {
        return new Date(a.date_end) - new Date(b.date_end);
      });

      if (newDates[0] !== undefined) {
        this.eventInfo = newDates[0]
        this.$store.dispatch("events/getUsersCheckin",this.eventInfo.id)

        this.html5QrCode = new Html5Qrcode("reader");
        const config = {fps: 10, qrbox: {width: 250, height: 250}};

        this.html5QrCode.start({facingMode: "environment"}, config, this.onScanSuccess, this.onScanFailure).catch(e => {
          console.log('er',e);
          this.msg = 'Error connecting to media device'
        });

        let audio = document.getElementById("sound1");
        audio.volume = 0.5;
      }
    },
    onScanSuccess(decodedText, decodedResult) {
      // handle the scanned code as you like, for example:
      //let eventid = new RegExp('/login\\/(.*)/')
      console.log(decodedResult);
      var regex = /[^login/]*$/g;
      var matches = decodedText.match(regex);
      decodedText = matches[0]

      this.html5QrCode.pause();

      //mark user completed
      this.$store.dispatch('events/markEventAttended', {eventid: this.eventInfo.id, userid: decodedText})
          .then(res => {
            //if success then stop scanner and display attended message
            if (res.attended !== undefined && res.attended) {
              this.msg = 'User marked attended';
              let self = this
              setTimeout(function () {
                self.msg = ''
              }, 2000);
              this.$store.dispatch("events/getUsersCheckin",this.eventInfo.id).then(() => {
                    document.getElementsByClassName('lastScan')[0].getElementsByTagName('tr')[1].classList.toggle('highlightRow')
                    setTimeout(function () {
                      document.getElementsByClassName('lastScan')[0].getElementsByTagName('tr')[1].classList.toggle('highlightRow')
                    }, 4000);
              })
              //this.$store.dispatch('utils/stopAllAudioVideo')
              //this.$router.push({'name': 'eventTicket', params:{eventId: decodedText }})
            }
            this.beep()
            this.html5QrCode.resume();
          })
          .catch(error => {
            //this.loading = false
            console.log('🤷‍️' + error.message)
            this.html5QrCode.resume();
          })

    },
    onScanFailure(error) {
      // handle scan failure, usually better to ignore and keep scanning.
      // for example:
      console.warn(`Code scan error = ${error}`);
    }
  },
  computed: {
    ...mapGetters({
      'listEvents': 'events/listEvents',
      'usersCheckedIn': 'events/usersCheckedIn'
    }),
  },
  mounted() {
    this.$store.dispatch("events/getEvents").then(() => {
      this.HasEvent()
    });


  },
}
</script>

<style scoped>
#reader__dashboard_section_swaplink {
  display: none !important;
}


.highlightRow {
  background-color: #d79d0b;
}



</style>