import axios from 'axios'

export default {
    namespaced: true,
    state: {
        guestForm: {},
        eventDetails: {},
        newEventDetails: {}
    },
    getters: {
        getEventDetails(state) {
            return state.eventDetails
        },
    },
    actions: {
        userSignup({commit},eventid){
            return new Promise((resolve, reject) => {
                let data = {eventid};
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/events/usereventsignup',
                    headers: {
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        commit('setNewEventDetails',response.data)
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        sponsorSignup({state},fdata){
            return new Promise((resolve, reject) => {
                let data = {...fdata};
                state.guestForm = data
                data.type = 'sponsor';
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/sponsorSignup',
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.log('Error', error)
                        reject(error);
                    });
            })
        },
        guestSignup({state},fdata) {
            return new Promise((resolve, reject) => {
                let data = {...fdata.form, signinType: fdata.signinType, otherLoginCreds: fdata.otherLoginCreds};
                state.guestForm = data
                data.type = 'guest';
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/site/eventsignup',
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.log('Error', error)
                        reject(error);
                    });
            })
        },
        guestEventDetails({commit},{eventid}) {
            return new Promise((resolve,reject) => {
                let data = {eventid};
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/site/signupDetails',
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        commit('setEventDetails',response.data)
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.log('Error', error)
                        reject(error);
                    });

            })
        }
    },
    mutations:{
        setEventDetails(state, eventdetails) {
            state.eventDetails = eventdetails
        },
        setNewEventDetails(state, eventdetails) {
            state.newEventDetails = eventdetails
        },
    }
}