<template>
  <!--
<div>
  <div>Tacostogether.com</div>
  <a href="/#/login">Login</a><br>

  Guest Signup
  <ul id="listEvents">
    <li v-for="event in listEvents" :key="event.id">
      <router-link :to="{ name: 'guestSignup', params: { eventId: event.id }}">{{event.name}}</router-link>
    </li>
  </ul>

  <router-link :to="{ name: 'sponsorSignup'}">Sponsor Signup</router-link>
</div>
  -->


  <div>


    <div class="about content-block">

      <div class="container">

        <div class="row">

          <div class="col-xs-12 col-md-3 aboutdiv">

            <h2 class="red">What is</h2>
            <h1 class="red mb-3">Tacos Together?</h1>

          </div>

          <div class="col-xs-12 col-md-9">

            <p>At its core, Tacos Together is community. Tacos Together started out with just a simple idea: let's just
              feed cool people tacos and see what magic happens.
              And that's what it still revolves around today. This is true relationship building. We don't charge
              membership fees. We don't sell your data. We don't corner you into high-pressure sales. Just cool
              experiences, good tacos, and great people.
            </p>

          </div>

          <div class="row justify-content-center mt-5 mb-5">
            <div class="col-md-4 col-sm-3 col-xs-2">
              <img :src="taco1" class="img-bg">
            </div>
            <div class="col-md-4 col-sm-3 col-xs-2">
              <img :src="taco2" class="img-bg">
            </div>
            <div class="col-md-4 col-sm-3 col-xs-2">
              <img :src="taco3" class="img-bg">
            </div>
          </div>

        </div>

      </div>
    </div>

    <div class="events content-block bg-red">

      <div class="container">
        <div class="row">

          <div class="col-xs-12 col-md-3 eventdiv">

            <h2 class="orange upclbl">Upcoming</h2>
            <h1 class="white mb-5">Events</h1>

          </div>

          <div class="col-xs-12 col-md-9">
            <div class="event-info mb-5" v-for="event in listEvents" :key="event.id">
              <div class="hasEvent" role="button" @click="toEvent(event.id)">
                <span class="mb-3"><i class="fa-solid fa-calendar-days d-inline-block orange"></i>
                  <h5 class="d-inline-block mx-2">{{ eventTime(event.date_start, event.date_end) }}</h5>
                </span>
                <span><i class="fa-solid fa-location-dot d-inline-block orange"></i> <p class="d-inline-block mx-2">
                  <!-- Creepy Warehouse (Future home of GYVR) 1657 N state Street, Lehi-->
                  {{ event.name }} {{ event.address }}, {{ event.address_city }} {{ event.address_state }} {{ event.address_postalcode }}
                </p>
                </span>
              </div>
              <div class="col-lg-9 col-md-12 col-xl-7 text-center">
                <router-link class="btn btn-primary text-center" style="width: 40%;"
                             :to="{ name: 'guestSignup', params: { eventId: event.id }}">Register
                </router-link>
              </div>

              <div class="col-lg-9 col-md-12 col-xl-7 mt-3 text-center">
                <a class="fw-bold donateToys text-decoration-none h4" href="https://joinangora.com/toy-drive-portal/"
                   target="_blank">Donate Toys Here</a>
              </div>

            </div>

          </div>

        </div>
      </div>

    </div>

    <div class="about content-block">

      <div class="container">

        <div class="row">

          <div class="col-xs-12 col-md-3 aboutdiv">

            <h2 class="red">The</h2>
            <h1 class="red mb-3">Taco Values</h1>

          </div>

          <div class="col-xs-12 col-md-9">

            <h4>Be Real</h4>
            <p>Let's all agree to leave the fakeness at the door. At a Tacos Together event, we want you to be real.
              Focus on real conversations that build real connections between real people.</p>

            <h4>Bring Value</h4>
            <p>Tacos Together is built on the idea that we can all bring value to those around us. Everyone, including
              you, has something special to offer this world. You have the ability to change someone's life.</p>

            <h4>Be Kind</h4>
            <p>Remember, we're all people. Tacos Together doesn't view you as a product. Even if we disagree with each
              other, we can be kind in how we choose to act.</p>

          </div>

        </div>

      </div>
    </div>

    <div class="sponsors bg-yellow content-block">

      <div class="container">
        <div class="row">

          <div class="col-xs-12 col-mb-3 sponsordiv">

            <h2 class="orange">Want to</h2>
            <h1 class="white">Sponsor?</h1>

          </div>

          <div class="col-xs-12 col-mb-9">
            <br>
            <p>Tacos Together events are a community effort, entirely impossible to do by ourselves. We are ever
              thankful to our sponsors for their generous and continued support. Thank you for investing in the
              Community and embarking on this journey with us.</p>

            <p>Interested in sponsoring future events? Reach out to <a href="mailto:paul@tacostogether.com">paul@tacostogether.com</a>
            </p>

          </div>

        </div>
      </div>

    </div>


  </div>

</template>

<script>
import {mapGetters} from 'vuex'
import moment from "moment-timezone";

export default {
  name: "HomePage",
  data() {
    return {
      tacoLogo: require('@/assets/images/tacosLogo.png'),
      taco1: require('@/assets/images/img-tacos1.jpg'),
      taco2: require('@/assets/images/img-tacos2.png'),
      taco3: require('@/assets/images/img-tacos3.jpg')
    }
  },
  methods: {
    toEvent(eventid) {
      this.$router.push({name: 'guestSignup', params: {eventId: eventid}})
    },
    eventTime(dst, det) {
      let day = moment(dst).format("MMMM Do YYYY");
      let startTime = moment(dst).format("h:mm a");
      let endTime = moment(det).format("h:mm a");

      return day + ' / ' + startTime + ' - ' + endTime;
    }
  },
  computed: {
    ...mapGetters({
      'listEvents': 'events/listEvents',
    }),
  },
  mounted() {
    this.$store.dispatch("events/getEvents")
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;800&display=swap');

.donateToys {
  color: #f6ff74;
}

</style>