<template>
  <div class="login-form">
    <p class="bg-success white" v-show="msg">
      {{ msg }}
    </p>
    <!--
    <div class="text-center mb-3">
      <GoogleLogin :callback="googleCallback"></GoogleLogin>
    </div>
    -->
    <form @submit.prevent="submitForm" autocomplete="off">
      <p class="alert alert-danger" role="alert" v-show="showemailverify">Please verify your email before logging in</p>
      <p class="alert alert-danger" role="alert" v-show="v$.form.username.$error">Username is invalid or empty</p>
      <p class="alert alert-danger" role="alert" v-show="v$.form.password.$error">Password is empty</p>
      <div class="form-group">
        <input type="text" v-model="form.username" v-on:keyup.enter="submitForm" id="username" class="form-control"
               placeholder="Username Or Email">
        <i class="fa fa-user"></i>
      </div>
      <div class="form-group log-status">
        <input type="password" class="form-control" v-on:keyup.enter="submitForm" v-model="form.password" id="password"
               placeholder="Password">
        <i class="fa fa-lock"></i>
      </div>
      <p class="alert alert-danger" role="alert" v-show="invalidLogin">Your username and password did not match. Please
        try again.</p>
      <v-btn type="submit" id="submitBtn" class="btn" color="success" :loading="loading" :disabled="loading"
             @click="loader(true)">
        Log In
      </v-btn>
      <div class="login-links">
        <router-link class="link" to="/login-problem">Can't access your account?</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import {mapGetters} from "vuex";
//import {decodeCredential} from "vue3-google-login";


export default {
  name: 'Login-page',
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      loading: false,
      msg: '',
      loginToEvent: '',
      invalidLogin: false,
      showemailverify: false,
      signinType: 'basic',
      otherLoginCreds: {},
      form: {
        username: null,
        password: null
      }
    }
  },
  validations() {
    return {
      form: {
        username: {required},
        password: {required}
      }
    }
  },
  computed: {
    ...mapGetters({
      'UserInfo': 'userAccess/user',
    }),
  },
  methods: {
    googleCallback(response) {
      // This callback will be triggered when the user selects or login to
      // his Google account from the popup

      if (response.credential) {
        this.signinType = 'google'
        this.otherLoginCreds.clientId = response.clientId
        this.otherLoginCreds.credential = response.credential

        this.submitForm()
      } else {
        console.log("a1")
      }
    },
    async submitForm() {
      let result = true;
      if (this.signinType == 'basic') {
        result = await this.v$.$validate()
      }

      if (!result) {
        return
      }
      // if (this.form.username && this.form.password) {
      this.$store.dispatch('userAccess/login2', {
        username: this.form.username,
        password: this.form.password,
        signinType: this.signinType,
        otherLoginCreds: this.otherLoginCreds
      })
          .then(res => {
            this.showemailverify = false
            this.invalidLogin = false
            if (!res.notfound && res.emailverified !== undefined && !res.emailverified) {
              this.showemailverify = true;
            } else if (this.$store.getters['userAccess/invalidLogin'] == false) {
              //this.$store.dispatch("sideMenu/menuRights")
              if (this.loginToEvent) {
                this.checkinEvent();
              } else {
                this.successRedirect()
              }
            } else if (this.$store.getters['userAccess/invalidLogin'] == true) {
              this.invalidLogin = true
            }

          })
          .catch(() => {
            this.loading = false
            // alert('🤷‍️' + error.message)
          })
      // }
    },
    successRedirect() {
      this.loading = false
      //const redirectTo = this.$route.query.redirectTo || {name: 'dashboard'}

      this.$router.push({name: 'myEvent', query:{'justLoggedIn': true}})
    },
    loader(lv) {
      this.loading = lv
      this.submitForm();
    },
    async checkinEvent() {
      let gdata = await this.$store.dispatch("events/getUserEvents")
      let edata = gdata.userEvents.find(x => x.id === this.loginToEvent)
      if (edata === undefined) {
        await this.$store.dispatch('signups/userSignup', this.loginToEvent)
      }

      gdata = await this.$store.dispatch("events/getUserEvents")
      edata = gdata.userEvents.find(x => x.id === this.loginToEvent)

      if (edata.haswaiver == '1' && edata.waiversigned === null) {
        //redirect to waiver page with checkin parameter after
        const redirectTo = {
          name: 'signWaiver',
          params: {autocheckin: true, waivertext: edata.waivertext, eventid: edata.id},
          query: {autocheckin: true, waivertext: edata.waivertext, eventid: edata.id}
        }
        this.$router.push(redirectTo)
      } else {
        this.$store.dispatch('events/markEventAttendedNoDataSet', this.loginToEvent)
            .then(() => {
              //this.successRedirect()
              this.loading = false
              //const redirectTo = this.$route.query.redirectTo || {name: 'dashboard'}
              const redirectTo = {name: 'eventTicket', params: {eventId: this.loginToEvent}}
              this.$router.push(redirectTo)
            })
            .catch(err => {
              alert('There was a problem checking in' + err);
            })
      }
    },
  }, mounted() {
    this.loginToEvent = this.$route.params.eventid
    if (this.$route.query.msg !== undefined) {
      this.msg = this.$route.query.msg
    }

    if (this.UserInfo.login) {
      if (this.loginToEvent) {
        this.checkinEvent();
      } else {
        this.successRedirect()
      }
    }
  },
  updated() {
    if (this.$route.query.msg !== undefined) {
      this.msg = this.$route.query.msg
    }
  }
}
</script>

<style scoped>

body {
  background: #5fd2e7;
}

.title-small {
  font-size: 20px;
  font-weight: bold;
}

.login-form {
  max-width: 450px;
  padding: 40px 20px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  margin: auto;
  position: relative;
  left: 0;
  right: 0;

}

.form-group {
  position: relative;
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  height: 40px;
  border: none;
  font-size: 15px !important;
  padding: 5px 7px 5px 15px;
  background: #fff;
  color: #666;
  border: 2px solid #ddd;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}

.form-control:focus, .form-control:focus + .fa {
  border-color: #10CE88;
  color: #10CE88;
}

.form-group .fa {
  position: absolute;
  right: 15px;
  top: 11px;
  color: #999;
}

.log-status.wrong-entry {
  -moz-animation: wrong-log 0.3s;
  -webkit-animation: wrong-log 0.3s;
  animation: wrong-log 0.3s;
}

.log-status.wrong-entry .form-control, .wrong-entry .form-control + .fa {
  border-color: #ed1c24;
  color: #ed1c24;
}

.btn {
  background: #FFF;
  display: inline-block;
  width: 100%;
  font-weight: bold !important;
  height: 40px;
  border: none;
  border: 1px solid #ced4da !important;
}

.btn:hover {
  background-color: #ced4da !important;
}

.login-links {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.link {
  text-decoration: none;
  color: #000;
  float: right;
  font-size: 15px;
  margin-bottom: 15px;
}


</style>
