<template>
  <div class="container justify-content-center mt-3 mb-2 border border-1 border-primary">
    <div class="h1 text-center m-3">Ugly Christmas Sweater Party</div>
    <div class="h4 text-center col-sm-5 mx-auto">Help us provide presents for </div>
    <div class="div h4 text-danger  text-center">10,000 kids</div>
    <div class="div row border border-1 border-dark" style="min-height: 250px">
      <div class="col-12 mx-auto col-sm-8 col-md-4">
        <div class="border border-primary h-100">Picture Goes Here</div>
      </div>
      <div class="col-12 mx-auto col-sm-8 col-md-8">
        <div class="border border-primary h-100">Need copy for this</div>
      </div>
    </div>
    <div class="div row border border-1 border-dark mt-3 bg-red" style="min-height: 250px">
      <div class="col-12 mx-auto col-sm-8 col-md-8">
        <div class="border border-primary h-100">Copy goes here</div>
      </div>
      <div class="col-12 mx-auto col-sm-8 col-md-4">
        <div class="border border-primary h-100">Picture goes here</div>
      </div>
    </div>
    <div class="div row border border-1 border-dark mt-3" style="min-height: 250px">
      <div class="col-12 mx-auto col-sm-8 col-md-4">
        <div class="border border-primary h-100">Picture Goes Here</div>
      </div>
      <div class="col-12 mx-auto col-sm-8 col-md-8">
        <div class="border border-primary h-100">Need copy for this</div>
      </div>
    </div>
    <div class="div col-6 bg-red text-center mx-auto">Register Button Here</div>
  </div>
</template>

<script>
export default {
  name: "EventDetails"
}
</script>

<style scoped>

</style>