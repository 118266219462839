<template>
  <div class="row">
    <div class="col-12" v-html="waivertext"></div>
  </div>

  <div class="row">
    <div class="col-6">
      Check here to sign
      <input type="checkbox" name="signed" @change="isCheck()" v-model="form.signed">
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <button v-show="signdisabled" class="btn btn-primary disabled" id="signedBtn" @change="waiveChk()"
              @click="signWaiver()">Sign
      </button>
    </div>
  </div>


</template>

<script>

export default {
  name: "signWaiver",
  data() {
    return {
      waivertext: '',
      signdisabled: true,
      autocheckin: false,
      form: {
        signed: false,
        eventid: ''
      }
    }
  },
  methods: {
    decodeHtml(html) {
      var txt = document.createElement("textarea");
      txt.innerHTML = html;
      return txt.value;
    },
    isCheck() {
      if (this.form.signed) {
        document.getElementById("signedBtn").classList.remove('disabled')
      } else {
        document.getElementById("signedBtn").classList.add('disabled')
      }
    },
    waiveChk() {
      if (this.form.signed) {
        this.signdisabled = false
      } else {
        this.signdisabled = true
      }
    },
    signWaiver() {
      if (!this.form.signed) {
        return;
      } else {
        this.$store.dispatch('events/signEventWaiver', this.form)
            .then(res => {
              if (res.waiver) {
                if (this.autocheckin) {
                  this.$store.dispatch('events/markEventAttendedNoDataSet', this.form.eventid)
                      .then(() => {
                        this.$router.push({
                          name: 'myEvent',
                          query: {waivepop: false}
                        })
                      })
                      .catch(err => {
                        alert('There was a problem checking in' + err);
                      })
                } else {
                  this.$router.push({
                    name: 'myEvent',
                    query: {waivepop: false}
                  })
                }
              }
            })
            .catch(error => {
              //this.loading = false
              console.log('🤷‍️' + error.message)
            })
      }
    }
  },
  mounted() {
    if (this.$route.query.waivertext === undefined) {
      this.$router.push({
        name: 'myEvent',
      })
    } else {
      this.waivertext = this.decodeHtml(this.$route.query.waivertext)
      this.form.eventid = this.$route.query.eventid
      this.autocheckin = this.$route.query.autocheckin
    }


  },
}
</script>

<style scoped>

</style>