<template>
  <div class="outerDiv border border-2 border-dark mt-5 me-3 p-2">

    <div class="div h1 text-center text-danger">Registered Guests Report</div>

    <div class="container-fluid mt-5">
      <div class="row mb-2">
        <div class="col-3">
          <select v-model="filters.event" class="w-100">
            <option value="" selected>-- Choose Event --</option>
            <option v-for="option in listAllEvents" :value="option.id" :key="option.id">
              {{ option.name }}
            </option>
          </select>
        </div>
        <div class="col-3">
          <button @click="filterReport">Search</button>
        </div>
        <div class="col-1 offset-5">
          <div class="div align-self-end">
            <button @click="downloadCSV">CSV</button>
          </div>
        </div>
      </div>
    </div>

    <DataTable
        :data="dataR"
        :options="options"
        class="display"
        width="95%"
    >

      <thead>
      <tr>
        <th>Name</th>
        <th>Email</th>
        <th>Phone</th>
        <th>Signed</th>
        <th>Attended</th>
        <th>Registered Data</th>
        <th>Event Registered Date</th>
      </tr>
      </thead>

    </DataTable>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import DataTable from 'datatables.net-vue3';
//import Buttons from 'datatables.net-buttons';
//import ButtonsHtml5 from 'datatables.net-buttons/js/buttons.html5';

//DataTable.use(Buttons);
//DataTable.use(ButtonsHtml5);


export default {
  name: "ReportsView",
  data() {
    return {
      filters: {
        event: ''
      },
      search: [],
      dataR: [],
      columns: [],
      header: ['Name', 'Email', 'Phone', 'Signed', 'Attended', 'Registered Data', 'Event Registered Date'],
      options: {dom: '<"top"f>rt<"bottom"ilp><"clear">', buttons: ['csv']}
    }
  },
  components: {
    DataTable,
  },
  computed: {
    ...mapGetters({
      'getReport': 'reports/getReportData',
      'getHeader': 'reports/getReportHeader',
      'listAllEvents': 'events/listAllEvents'
    }),
  },
  methods: {
    filterReport() {
      this.$store.dispatch("reports/getReport", this.filters).then(() => {
        this.dataR = this.getReport
        this.columns = this.getHeader
      })
    },
    downloadCSV() {
      let newDT = [this.header, ...this.dataR];
      let csv = this.arrayToCsv(newDT);
      this.downloadBlob(csv, 'export.csv', 'text/csv;charset=utf-8;')
    },
    arrayToCsv(data) {
      return data.map(row =>
          row
              .map(String)  // convert every value to String
              .map(v => v.replaceAll('"', '""'))  // escape double colons
              .map(v => `"${v}"`)  // quote it
              .join(',')  // comma-separated
      ).join('\r\n');  // rows starting on new lines
    },
    downloadBlob(content, filename, contentType) {
      // Create a blob
      let blob = new Blob([content], {type: contentType});
      let url = URL.createObjectURL(blob);

      // Create a link to download it
      let pom = document.createElement('a');
      pom.href = url;
      pom.setAttribute('download', filename);
      pom.click();
    }
  },
  mounted() {

    /*
    this.$store.dispatch("reports/getReport").then(() => {
      this.dataR = this.getReport
      this.columns = this.getHeader
    })
    */
    this.$store.dispatch("events/getAllEvents").then(() => {

    });
  },
}
</script>

<style>


.outerDiv {
  font-size: 12px;
}

@import 'datatables.net-dt';

.dataTables_filter {
  float: left !important;
}


</style>