import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import Maska from 'maska'
import VueGtag from "vue-gtag";
import '@/assets/fontawesome/css/all.css';
import '@/assets/main.css';
import vue3GoogleLogin from 'vue3-google-login'

/*

.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GMAPS,
    },
})
 */

createApp(App).use(store).use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GMAPS,
    },
}).use(Maska)
  .use(vue3GoogleLogin, {
    clientId: '464332945626-mvqi5s6376ft7kq6gjol17rtt5tbjpav.apps.googleusercontent.com'
}).use(VueGtag, {
    config: {
        id: "G-3TTYX840CF",
    },
}, router).use(router).use(VueLoading).mount('#app')
