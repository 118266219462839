<template>
  <div>
  </div>
</template>

<script>
export default {
  name: "eVerify",
  props: ['vid'],
  mounted() {
    this.$store.dispatch("userAccess/verifyEmail",this.vid).then(res => {
      if(res.verified !== undefined && res.verified){
        this.$router.push({name: 'Login', query:{msg:'Your account has been verified, please continue by loging in', 'firstLogon': true}})
      }else{
        this.$router.push({name: 'Login', query:{msg:'This link has already been used'}})
      }
    })
  }
}
</script>

<style scoped>

</style>