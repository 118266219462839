<template>
  <div>
    <div id="reader" class="w-100"></div>
  </div>
</template>

<script>
//import {Html5QrcodeScanner, Html5QrcodeScanType} from "html5-qrcode"
import {Html5Qrcode} from "html5-qrcode"

export default {
  name: "ScanEvent",
  data() {
    return {
      html5QrCode: ''
    }
  },
  methods: {
    onScanSuccess(decodedText, decodedResult) {
      // handle the scanned code as you like, for example:
      //let eventid = new RegExp('/login\\/(.*)/')
      console.log(decodedResult)
      var regex = /[^login/]*$/g;
      var matches = decodedText.match(regex);
      decodedText = matches[0]

      this.html5QrCode.pause();

      //mark user completed
      this.$store.dispatch('events/markEventAttended', decodedText)
          .then(res => {
            //if success then stop scanner and display attended message
            if (res.attended !== undefined && res.attended) {
              this.$store.dispatch('utils/stopAllAudioVideo')
              this.$router.push({'name': 'eventTicket', params:{eventId: decodedText }})
            }
          })
          .catch(error => {
            //this.loading = false
            console.log('🤷‍️' + error.message)
            this.html5QrCode.resume();
          })

    },
    onScanFailure(error) {
      // handle scan failure, usually better to ignore and keep scanning.
      // for example:
      console.warn(`Code scan error = ${error}`);
    }
  },
  mounted() {

    this.html5QrCode = new Html5Qrcode("reader");
    const config = { fps: 10, qrbox: { width: 250, height: 250 } };

    this.html5QrCode.start({ facingMode: "environment" }, config, this.onScanSuccess,this.onScanFailure);
  },
}
</script>

<style scoped>
  #reader__dashboard_section_swaplink {display: none !important;}
</style>