<template>

  <div class="container-fluid">
    <div class="row justify-content-around">
      <div class="col-xs-11 col-md-11 mt-4">
        <h1 class="text-center">My Events</h1>
        <h3 class="text-center">Events You're Registered to Attend</h3>
      </div>
    </div>
    <div class="row justify-content-around">
      <div class="col-xs-11 col-md-11 mt-5 text-center">
        <p>
          Use this QR code below to check in to the event.
          <br>
          Make sure to have it on hand when you arrive to ensure a quick and easy entry!
          <br>
          Can't wait to see you there!
        </p>
      </div>
    </div>

    <div class="row justify-content-around">
      <div class="col-xs-11 col-md-11 mt-4 text-center justify-content-around">
        <qr-code :text="qrcodeValue" class="qrcode" size="125"></qr-code>
        <div class="div h4 text-danger mt-4" style="font-weight: bold">After you register <br> This is your ticket for all events</div>
      </div>
    </div>

    <br>
    <br>
    <div class="row justify-content-around">
      <div class="col-xs-11 col-md-11 mt-4">
        <div class="event-info mb-5 text-center" v-if="myEvents !== undefined && myEvents.length <= 0">
          <h4 class="centerthis">You are not registered for any events</h4>
        </div>
        <div class="event-info mb-5 text-center" v-for="event in myEvents" :key="event.id">
                <span class="mb-1">
                  <i class="fa-solid fa-calendar-days d-inline-block orange"></i>
                  <h5 class="d-inline-block mx-2" v-html="eventTime(event.date_start, event.date_end)"></h5>

                </span>
                <span><i class="fa-solid fa-location-dot d-inline-block orange"></i>

                     <p class="d-inline-block mx-2 justify-content-around">
                       <a :href="'https://maps.google.com/?q='+event.lat+','+event.lng" target="_blank">
                      {{ event.name }} {{ event.address }} {{ event.address_city }}, {{
                           event.address_state
                         }} {{ event.address_postalcode }}
                       </a>
                     </p>
                </span>
          <div>
            <GMapMap
                :center="{lat: event.lat, lng: event.lng}"
                :zoom="15"
                map-type-id="terrain"
                class="gmapSize"
            >
              <GMapCluster>
                <GMapMarker
                    :key="index"
                    :position="{lat: event.lat, lng: event.lng}"
                    :clickable="false"
                    :draggable="false"
                />
              </GMapCluster>
            </GMapMap>
          </div>
          <div class="row text-center mt-3" v-if="!isDatePassed(event)">
            <div class="col" v-show="!needsWaiver(event) && !event.attended">
              <!-- if event is passed don't show either button or maybe don't show even at all -->
              <router-link :to="{name:'scanEvent'}" class="btn btn-primary p-2"
              ><small>Check-In</small>
              </router-link>
            </div>
            <div class="col" v-show="needsWaiver(event)">
              <router-link :to="{name:'signWaiver', query:{waivertext: event.waivertext, eventid: event.id }}"
                           class="btn btn-primary text-nowrap p-2"><small>Sign Waiver</small>
              </router-link>
            </div>
            <div class="col" v-show="!event.attended">
              <button
                  class="btn btn-primary  p-2"
                  @click="unregister(event.id)"
              ><small>Unregister</small>
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="waiverModal" tabindex="-1" aria-labelledby="waiverModalLbl" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="waiverModalLbl">Waiver</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            Please sign waivers to your events
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-danger" data-bs-dismiss="modal" @click="signWaiver">Sign Waiver
            </button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import {mapGetters} from 'vuex'
import {Modal} from 'bootstrap'
import moment from "moment-timezone";
import VueQRCodeComponent from 'vue-qr-generator'

export default {
  name: "HomePage",
  data() {
    return {
      waiverpop: false,
      waiverpopEvent: {},
      qrcodeValue: '',
      size: 100,
    }
  },
  components: {
    'qr-code': VueQRCodeComponent,
  },
  computed: {
    ...mapGetters({
      'myEvents': 'events/listUserEvents',
      'userInfo': 'userAccess/user'
    }),
  },
  methods: {
    signWaiver() {
      this.$router.push({
        name: 'signWaiver',
        query: {waivertext: this.waiverpopEvent.waivertext, eventid: this.waiverpopEvent.id}
      })
    },
    unregister(eventid) {
      this.waiverpop = false
      this.$store.dispatch("events/unregisterEvent", eventid).then(data => {
        console.log('a', data)
      })
    },
    isDatePassed(eventObj) {
      if (eventObj && moment(eventObj.date_end).isBefore(moment())) {
        return true
      }
      return false
    },
    needsWaiver(eventObj) {
      let needsWaiver = false
      if (eventObj && eventObj.haswaiver && eventObj.waiversigned == null) {
        needsWaiver = true
        this.waiverpop = true
        this.waiverpopEvent = eventObj
      }
      return needsWaiver;
    },
    eventTime(dst, det) {
      let ndt = '';
      let day = moment(dst).format("MMMM Do YYYY");
      let startTime = moment(dst).format("h:mm a");
      let endTime = moment(det).format("h:mm a");
      ndt = '<div><span>' + day + '</span> <span class="text-nowrap">' + startTime + ' - ' + endTime + '</span></div>';
      return ndt
    },
  },
  mounted() {
    this.qrcodeValue = this.userInfo.userid
    let query = this.$route.query;
    let self = this
    this.$store.dispatch("events/getUserEvents").then(() => {
      if (this.$route.query.waivepop === undefined) {
        setTimeout(function () {
          if (self.waiverpop) {
            if (query.justLoggedIn) {
              self.signWaiver()
            } else {
              let myModal = new Modal(document.getElementById('waiverModal'));
              myModal.show();
            }
          }
        }, 1000)
      }
    })
  },
}
</script>

<style scoped>


.qrcode {
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  display: inline-block;
  border: 6px solid #000;
  padding: 15px;
}

.gmapSize {
  width: 100%;
  height: 300px;
}

.centerthis {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 70%;
  text-align: center;
}

div img {
  display: unset !important;
}

/*
@media (max-width: 767.98px) {
  .gmapSize {
    width: 200px;
    height: 200px;
  }
}
 */
</style>