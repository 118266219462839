import { createStore } from 'vuex'
import userAccess from './modules/login'
import signups from "@/store/modules/signups";
import events from "@/store/modules/events"
import utils from "@/store/modules/utils";
import reports from "@/store/modules/reports";

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    userAccess,
    signups,
    events,
    utils,
    reports
  }
})
