<template>
  <div class="container">

    <div class="row text-center" v-if="Object.keys(UserInfo).length > 0">
      <div class="col" v-show="!isDatePassed(this.eventData)">
        <h5 class="mt-3 mx-2 text-center tacoColor pb-2" v-show="isSignedUp">You are attending this event</h5>
        <button v-show="this.nd && !isSignedUp" style="width: 200px;" type="button"
                class="mt-3 text-center btn btn-primary {{!isSignedUp ? 'd-none' : ''}}" @click="signUp">Sign Up
        </button>

        <router-link :to="{name:'scanEvent'}" class="btn btn-primary"
                     v-show="this.nd && !this.waiverpop && this.eventData && !this.eventData.attended">Check-In
        </router-link>

        <router-link
            :to="{name:'signWaiver', query:{waivertext: this.eventData ? this.eventData.waivertext : '', eventid: this.eventData ? this.eventData.id : '' }}"
            class="btn btn-primary" v-show="this.nd && this.waiverpop">Sign Waiver
        </router-link>

        <!--
        <router-link :to="{name:'eventTicket', query:{eventId: this.eventData ? this.eventData.id : '' }}"
                     class="btn btn-primary" v-show="this.nd && this.eventData && this.eventData.attended">Your Ticket
        </router-link>
        -->
      </div>

      <div class="row text-center mt-1">
        <div class="col">
          <div class="event-info mb-3">
                <span class="mb-3"><i class="fa-solid fa-calendar-days d-inline-block orange"></i>
                  <h5 class="d-inline-block mx-2">{{ eventTime() }}</h5>
                </span>
            <span><i class="fa-solid fa-location-dot d-inline-block orange"></i>
                     <p class="d-inline-block mx-2">
                      {{ eventLocation }}
                     </p>
                </span>
          </div>
        </div>

      </div>
      <div class="row mb-3">
        <div class="col-12">
          <a class="fw-bold tacoColor text-decoration-none h4" href="https://joinangora.com/toy-drive-portal/" target="_blank">Donate Toys Here</a>
        </div>
      </div>
      <div class="row mb-3">
        <div class="col-2">

        </div>
        <div class="col-8">

          <GMapMap
              :center="center"
              :zoom="15"
              map-type-id="terrain"
              :class="UserInfo.first_name != undefined && UserInfo.first_name != '' ? 'gmapSize' : 'gmapSizeSM' "
          >
            <GMapCluster>
              <GMapMarker
                  :key="index"
                  :position="center"
                  :clickable="false"
                  :draggable="false"
              />
            </GMapCluster>
          </GMapMap>


        </div>
        <div class="col-2">
        </div>
      </div>
    </div>


    <form v-if="Object.keys(UserInfo).length === 0" @submit.prevent="submitForm">
      <div class="row">
        <div class="col-xs-1 col-sm-6">
          <div class="col-12 mt-4">
            Please take a minute to create your Tacos Together account. This will register you for this event and allow
            you access to future features we're currently working on. Don't worry, we will never sell your data, we may
            contact you regarding future events, but you can always opt out. Thanks for being part of the taco
            community!
          </div>
          <div class="col-12 mt-4">
            <div class="col-12 mb-3">
              <label for="first_name" class="form-label">Name</label>
              <input type="text" @blur="v$.form.name.$touch"
                     :class="{'border border-danger': v$.form.name.$error}" class="form-control"
                     v-model="form.name" id="first_name">
              <p class="alert alert-danger p-1 mt-2" role="alert" v-if="v$.form.name.$error">First and Last Name Required</p>
            </div>
            <div class="col-12 mb-3">
              <label for="email" class="form-label">Email address</label>
              <input type="email" @blur="v$.form.email.$touch" :class="{'border border-danger': v$.form.email.$error}"
                     class="form-control" v-model="form.email" id="email" aria-describedby="emailHelp">
              <p class="alert alert-danger p-1 mt-2" role="alert" v-if="v$.form.email.$error">Please enter a valid
                email.</p>
              <p class="alert alert-danger p-1 mt-2" role="alert" v-if="emailTaken">Email already used</p>
            </div>
            <div class="col-12 mb-3">
              <label for="email" class="form-label">Phone</label>
              <input type="text" @blur="v$.form.phone.$touch" :class="{'border border-danger': v$.form.phone.$error}"
                     class="form-control" v-model="form.phone" id="email" aria-describedby="emailHelp"
                     v-maska="['+1 (###) ###-####', '+1 (###) ###-####']"
              >
              <p class="alert alert-danger p-1 mt-2" role="alert" v-if="v$.form.phone.$error">Please enter a cell phone
                number.</p>
            </div>
            <div class="col-12 mb-3">
              <label for="signup-password">Create Password</label>
              <input v-model="form.password" @blur="v$.form.password.$touch"
                     :class="{'border border-danger': v$.form.password.$error}" type="password" class="form-control"
                     id="signup-password">
              <p class="alert alert-danger p-1 mt-2" role="alert" v-if="v$.form.password.$error">Enter a password with 5
                characters or more.</p>
            </div>
            <div class="col-12 mb-3">
              <label for="signup-password-confirm">Confirm Password</label>
              <input v-model="form.passwordConfirm" @blur="v$.form.passwordConfirm.$touch" type="password"
                     :class="{'border border-danger': v$.form.passwordConfirm.$error}" class="form-control"
                     id="signup-password-confirm">
              <p class="alert alert-danger p-1 mt-2" role="alert" v-if="v$.form.passwordConfirm.$error">Please make sure
                passwords match.</p>
            </div>
            <div class="col-12 text-center mb-3">
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
            <!--
            <div class="col-12 text-center mb-3">
              <b>Or Sign Up With</b>
            </div>

            <div class="col-12 text-center mb-3">
              <GoogleLogin :callback="googleCallback"></GoogleLogin>
            </div>
            -->
          </div>
        </div>
        <div class="col-xs-1 col-sm-6">
          <div class="col-12 mt-4">
            <div class="col-12 mb-3">
              <div class="event-info mb-3">
                <span class="mb-3"><i class="fa-solid fa-calendar-days d-inline-block orange"></i>
                  <h5 class="d-inline-block mx-2">{{ eventTime() }}</h5>
                </span>
                <span><i class="fa-solid fa-location-dot d-inline-block orange"></i>
                     <p class="d-inline-block mx-2">
                      {{ eventLocation }}
                     </p>
                </span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-12 text-center">
                <a class="fw-bold tacoColor text-decoration-none h4" href="https://joinangora.com/toy-drive-portal/" target="_blank">Donate Toys Here</a>
              </div>
            </div>
            <div class="col-12 mb-3">
              <GMapMap
                  :center="center"
                  :zoom="15"
                  map-type-id="terrain"
                  :class="UserInfo.first_name != undefined && UserInfo.first_name != '' ? 'gmapSize' : 'gmapSizeSM' "
              >
                <GMapCluster>
                  <GMapMarker
                      :key="index"
                      :position="center"
                      :clickable="false"
                      :draggable="false"
                  />
                </GMapCluster>
              </GMapMap>

            </div>
          </div>
        </div>
      </div>
    </form>

    <!-- Modal -->
    <div class="modal fade" id="googleLinkModal" tabindex="-1" aria-labelledby="googleLinkModalLbl" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="googleLinkModalLbl">Link</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            A account with this email has been found would you like to link to this account?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="linkGoogleAccount">Link
            </button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import {required, email, sameAs, minLength} from '@vuelidate/validators'
import {mapGetters} from 'vuex'
import moment from "moment-timezone";
import {Modal} from "bootstrap";
//import { decodeCredential } from 'vue3-google-login'

const hasFirstLast = (value) => value.trim().split(/\s+/).length > 1

export default {
  name: "guestSignup",
  props: ['eventId'],
  setup: () => ({v$: useVuelidate()}),
  data() {
    return {
      nd: false,
      center: {},
      markers: [],
      emailTaken: false,
      isSignedUp: false,
      eventData: '',
      waiverpop: false,
      signinType: 'basic',
      linkGoogle: false,
      otherLoginCreds: {},
      form: {
        name: null,
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        password: null,
        passwordConfirm: null,
        eventid: this.eventId
      }
    }
  },
  validations() {
    return {
      form: {
        email: {required, email},
        phone: {required},
        password: {required, min: minLength(5)},
        passwordConfirm: {required, sameAsPassword: sameAs(this.form.password)},
        name: {required, hasFirstLast},
      }
    }
  },
  computed: {
    ...mapGetters({
      'getEventDetails': 'signups/getEventDetails',
      'UserInfo': 'userAccess/user',
      'userEvents': 'events/listUserEvents',
    }),
    eventLocation() {
      let loc = ''
      if (this.getEventDetails.data !== undefined && this.getEventDetails.data.name !== undefined) {
        loc = this.getEventDetails.data.name + ' ' + this.getEventDetails.data.address + ' ' + this.getEventDetails.data.address_city + ' ' + this.getEventDetails.data.address_state + ' ' + this.getEventDetails.data.address_postalcode
      }
      return loc
    }
  },
  methods: {
    signWaiver(event){
      this.$router.push({name:'signWaiver', query:{waivertext: event.waivertext, eventid: event.id }})
    },
    linkGoogleAccount() {
      this.otherLoginCreds.linkGoogle = true
      this.submitForm();
    },
    googleCallback(response) {
      // This callback will be triggered when the user selects or login to
      // his Google account from the popup

      if (response.credential) {
        this.signinType = 'google'
        this.otherLoginCreds.clientId = response.clientId
        this.otherLoginCreds.credential = response.credential

        this.submitForm()
      } else {
        console.log("Call the endpoint which validates authorization code")
      }
    },
    isDatePassed(eventObj) {
      if (eventObj && moment(eventObj.date_end).isBefore(moment())) {
        return true
      }
      return false
    },
    needsWaiver(waiverRedirect) {
      let eventObj = this.eventData
      let needsWaiver = false
      if (eventObj && eventObj.haswaiver && eventObj.waiversigned == null) {
        needsWaiver = true
        if (waiverRedirect) {
          //redirect to waiver
          this.$router.push({
            name: 'signWaiver',
            query: {
              waivertext: this.eventData ? this.eventData.waivertext : '',
              eventid: this.eventData ? this.eventData.id : ''
            }
          })
        } else {
          this.waiverpop = true
        }
      }
      this.nd = true
      return needsWaiver;
    },
    eventTime() {
      let ndt = '';
      if (this.getEventDetails.data !== undefined &&
          this.getEventDetails.data.date_start !== undefined &&
          this.getEventDetails.data.date_end !== undefined
      ) {
        let dst = this.getEventDetails.data.date_start;
        let det = this.getEventDetails.data.date_end;
        let day = moment(dst).format("MMMM Do YYYY");
        let startTime = moment(dst).format("h:mm a");
        let endTime = moment(det).format("h:mm a");
        ndt = day + ' / ' + startTime + ' - ' + endTime;
      }

      return ndt
    },
    signUp() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });
      this.$store.dispatch('signups/userSignup', this.eventId)
          .then(res => {
            if (res.added != undefined && res.added) {
              this.isSignedUp = res.added
              this.init(true)
            }
            loader.hide()
          }).catch(error => {
        loader.hide()
        console.log('🤷‍️' + error.message)
      })
    },
    async submitForm() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });
      let result = true
      if (this.signinType == 'basic') {
        result = await this.v$.$validate()
      }

      if (!result) {
        loader.hide()
        return;
      } else {
        if (this.form.phone !== null) {
          this.form.phone = this.form.phone.replace(/[^\d+]/g, '');
        }
        if(this.name !== null){
          let nSArr = this.form.name.trim().split(/\s+/)
          this.form.first_name = nSArr[0];
          delete nSArr[0];
          this.form.last_name = nSArr.join(' ').trim();
        }

        this.$store.dispatch('signups/guestSignup', {
          form: this.form,
          signinType: this.signinType,
          otherLoginCreds: this.otherLoginCreds
        })
            .then(async res => {
              if (this.signinType !== undefined &&
                  this.signinType == 'google' &&
                  res.data.email !== undefined &&
                  res.data.email &&
                  res.data.otherLogin !== undefined &&
                  !res.data.otherLogin) {
                let myModal = new Modal(document.getElementById('googleLinkModal'));
                myModal.show();

              } else {
                this.emailTaken = false;
                if (res.data.email !== undefined && res.data.email) {
                  this.emailTaken = true;
                }

                if (res.data.signedup !== undefined && res.data.signedup) {
                  if (res.login !== undefined && res.login.token !== undefined) {
                    await this.$store.dispatch("userAccess/setLogin", res.login);
                    this.init(true)
                  } else {
                    this.$router.push({
                      name: 'Login',
                      query: {msg: 'A verification email has been sent please click the link in the email before logging in.'}
                    })
                  }
                }
              }
              loader.hide()
            })
            .catch(error => {
              console.log(error);
              loader.hide()
            })
      }
    },
    init(waiverRedirect) {
      if (this.UserInfo) {
        this.$store.dispatch("events/getUserEvents").then(() => {
          if(this.userEvents !== undefined) {
            var d = this.userEvents.filter(u => u.id === this.eventId)
            this.eventData = d[0];
            this.needsWaiver(waiverRedirect)
            if (d.length > 0) {
              this.isSignedUp = true
            } else {
              this.isSignedUp = false
            }
          }
        })
      }
      this.$store.dispatch("signups/guestEventDetails", {eventid: this.$props.eventId}).then(data => {
        this.center = {lat: parseFloat(data.data.lat), lng: parseFloat(data.data.lng)}
      })
    }
  },
  mounted() {
    this.init()
  },
}
</script>

<style scoped>
.gmapSize {
  width: 100%;
  height: 300px;
}

.gmapSizeSM {
  width: 100%;
  height: 400px;
}

.tacoColor {
  color: #d62828;
}
</style>