<template>
  <div class="container ticketBack">
    <div class="row">
      <div class="col d-flex align-items-center justify-content-center">
        <span v-html="firstname"></span>
      </div>
    </div>
    <div class="row">
      <div class="col d-flex align-items-center justify-content-center">
        <span v-html="lastname"></span>
      </div>
    </div>
    <div class="row tickCenter">
      <div class="col d-flex align-items-center justify-content-center">
        <img :src="image" alt="tacoticket" class="img-fluid">
      </div>
    </div>
    <div class="row rbtom" >
      <div class="col">
        <div class="row">
          <div class="col d-flex align-items-center justify-content-center">
            <span v-html="eventName"></span><br><br><br>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex align-items-center justify-content-center">
            <span class="dateTime" v-html="eventDate"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: "eventTicket",
  data() {
    return {
      firstname: '',
      lastname: '',
      image: require('@/assets/images/taco-et.webp'),
      eventName: '',
      eventDate: ''
    }
  },
  computed: {
    ...mapGetters({
      'myEvents': 'events/listUserEvents',
      'userDetails': 'userAccess/user',
    }),
  },
  methods: {
    displayTicket() {
      let eventid = this.$route.params.eventId
      let key = Object.keys(this.myEvents).find(key => this.myEvents[key].id === eventid);
      const result = this.myEvents[key]
      if (result) {
        if(result.attended){
          //re route
          this.$router.push({
            name: 'myEvent',
          })
        }else {
          this.eventName = result.name
          this.firstname = this.userDetails.first_name
          this.lastname = this.userDetails.last_name
          this.$store.dispatch("utils/eventTimeU", {dst: result.date_start, det: result.date_end}).then(d => {
            this.eventDate = d;
          });
        }
      }
    },
  },
  mounted() {
    this.$store.dispatch("events/getUserEvents").then(() => {
      this.displayTicket()
    })

  }
}
</script>

<style scoped>
.dateTime {white-space: nowrap;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .dateTime {
    font-size:16px;
    margin-bottom: 20px;
    padding-bottom: 30px;
  }
}

.rbtom {
  position: absolute; bottom:0; width: 100%;
}

.centered-element {
  margin: 0;
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
}

.fixed-row-bottom {
  position: fixed;
  bottom: 0;
}

.ticketBack {
  position: relative;
  background-color: green;
  color: white;
  height: 97%;
  font-size: 20px;
  padding-top:5px;
  /*
  max-height: 500px;
  max-width: 500px;
   */
}

.tickCenter {
  height:75%;
}
</style>