import axios from 'axios'

export default {
    namespaced: true,
    state: {
       reportData:[],
       reportHeader:[]
    },
    getters: {
        getReportData(state) {
            return state.reportData
        },
        getReportHeader(state) {
            return state.reportHeader
        },
    },
    actions: {
        getReport({commit},filters){
            return new Promise((resolve, reject) => {
                let data = {...filters};
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/reports/userReport',
                    headers: {
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                };

                console.log(data);

                axios(config)
                    .then(function (response) {
                        commit('setReportData',response.data.reportData)
                        commit('setReportHeader',response.data.reportHeader)
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        }
    },
    mutations:{
        setReportData(state, report) {
            state.reportData = report
        },
        setReportHeader(state, header) {
            let newHeader = [];
            header.forEach((element) => {
                newHeader.push({'title': element});
            });
            state.reportHeader = newHeader
        },
    }
}