<template>
  <div>
    <div v-if="$route.meta.requiresAuth" class="row flex-nowrap">
      <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 taconav">
        <div class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100">
          <a href="/" class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none">
            <span class="d-sm-none d-sm-inline mx-1 tacotext">
              <img :src="tacoT" alt="Tacos Together" class="tacoLogo" style="width:25px;">
            </span>
            <span class="fs-5 d-none d-sm-inline">
              <img :src="tacoLogo" alt="Tacos Together" class="tacoLogo">
            </span>
          </a>
          <ul class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start tacotext"
              id="menu">
            <li class="nav-item">
              <a href="#" @click.prevent.stop="goTo({name:'myEvent'})" class="nav-link align-middle px-0">
                <i class="fs-4 bi-ticket"></i> <span class="ms-1 d-none d-sm-inline">My Events</span>
              </a>
              <!--<router-link :to="{name:'myEvent'}">My Events</router-link>-->
            </li>
            <li>
              <a href="#" @click.prevent.stop="goTo({name:'Home', query:{scrollTo:'.eventdiv'}})" class="nav-link align-middle px-0">
                <i class="fs-4 bi-calendar"></i> <span class="ms-1 d-none d-sm-inline">Event Schedule</span>
              </a>
              <!--<router-link :to="{name:'Home', params:{scrollTo:'.eventdiv'}}">Event Schedule</router-link>-->
            </li>
            <li v-if="canUserScan">
              <a href="#" @click.prevent.stop="goTo({name:'checkInUser'})" class="nav-link align-middle px-0">
                <i class="fs-4 bi-qr-code-scan"></i> <span class="ms-1 d-none d-sm-inline">Check Users In</span>
              </a>
              <!--<router-link :to="{name:'Home', params:{scrollTo:'.eventdiv'}}">Event Schedule</router-link>-->
            </li>
            <li v-if="canUserReports">
              <a href="#" @click.prevent.stop="goTo({name:'reports'})" class="nav-link align-middle px-0">
                <i class="fs-4 bi-file-text"></i> <span class="ms-1 d-none d-sm-inline">Reports</span>
              </a>
              <!--<router-link :to="{name:'Home', params:{scrollTo:'.eventdiv'}}">Event Schedule</router-link>-->
            </li>
          </ul>
          <hr>
          <div class="dropdown pb-4 fixed-bottom">
            <a href="#" class="d-flex align-items-center text-decoration-none dropdown-toggle tacotext"
               id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
              <!--<span class="d-sm-inline mx-1 tacotext">{{ userInfo.username }}</span>-->
              <!-- <img src="https://github.com/mdo.png" alt="hugenerd" width="30" height="30" class="rounded-circle"> -->
              <i class="fs-4 bi-person-circle tacotext"></i>
              <span class="d-none d-sm-inline mx-1 tacotext">{{ uName }}</span>
            </a>

            <ul class="dropdown-menu text-small shadow tacotext" aria-labelledby="dropdownUser1">
              <li><button class="dropdown-item" @click="logout()" href="#">Sign out {{ uName }}</button></li>
            </ul>

          </div>
        </div>
      </div>

      <div class="col">
        <router-view :key="$route.path"/>
      </div>

    </div>

    <div v-if="!$route.meta.requiresAuth">
      <header class="header header-transparent">

        <nav class="navbar navbar-light navbar-expand-lg" id="navbar-main">
          <div class="container px-lg-0">

            <button class="navbar-toggler collapsed pr-0" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-main-collapse"
                    aria-controls="navbar-main-collapse" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <!-- Navbar nav -->
            <div class="navbar-collapse collapse" id="navbar-main-collapse">
              <ul class="navbar-nav align-items-lg-center">
                <li class="nav-item active">
                  <router-link class="nav-link" :to="{ name: 'Home'}">Home</router-link>
                </li>

                <li class="nav-item active">
                  <span class="nav-link" style="cursor: pointer;" @click="scrollToElement('.aboutdiv')">About Us</span>
                </li>

                <li class="nav-item active">
                  <span class="nav-link" style="cursor: pointer;" @click="scrollToElement('.eventdiv')">Events</span>
                </li>

                <li class="nav-item d-xl-block">
                  <span class="nav-link" target="_blank" style="cursor: pointer;" @click="scrollToElement('.sponsordiv')">Sponsors</span>
                </li>

              </ul>

              <ul class="navbar-nav align-items-lg-center ml-lg-auto">

                <li class="nav-item d-xl-block">
                  <router-link id="siLink" class="nav-link" :to="{ name: 'Login'}" v-show="uName === undefined">Sign in</router-link>


                  <div class="dropdown d-none d-lg-block" v-if="uName !== undefined">
                    <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                       id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false" :to="{ name: 'myEvent'}">
                       <span class="d-none d-sm-inline mx-1 nav-link">{{ uName }}</span>

                    </a>
                    <ul class="dropdown-menu text-small shadow" aria-labelledby="dropdownUser1">
                      <li><router-link class="dropdown-item" :to="{ name: 'myEvent'}">My Events</router-link></li>
                      <li><button class="dropdown-item" @click="logout()" href="#">Sign out {{ uName }}</button></li>
                    </ul>

                  </div>
                </li>
                <li><router-link class="nav-link d-lg-none" :to="{ name: 'myEvent'}" v-show="uName !== undefined">My Events</router-link></li>
                <li><a class="nav-link d-lg-none" @click="logout()" v-show="uName !== undefined">Sign out {{ uName }}</a></li>

              </ul>
            </div>
          </div>
        </nav>

      </header>
      <div class="main">
        <div class="hero bg-yellow content-block">

          <div class="container">

            <div class="row">

              <div class="col text-center">
                <router-link :to="{ name: 'Home'}">
                  <img :src="ttlogo" class="tacoLogo" alt="Tacos Logo">
                </router-link>

                <p class="tag-line mt-4">Come by for the tacos, stay for the people</p>

                <div v-show="$route.name == 'Home'" class="register-banner">
                  <span class="d-inline-block mx-2">Join us for our next event!</span>
                  <a class="btn btn-primary d-inline-block" @click="scrollToElement('.eventdiv')">Register</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <router-view :key="$route.path"/>
        <footer class="bg-lyellow content-block">

          <div class="container">
            <div class="row text-center">
              <div class="col">
                <img :src="chinstrapLogo" class="tacoLogo" style="width: 75px;" alt="Chinstrap Software">
              </div>
              <div class="vfont">
                {{version}}
              </div>
            </div>
          </div>

        </footer>
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import { version } from "../package.json";
import { Capacitor } from '@capacitor/core';

export default {
  data() {
    return {
      plateform: Capacitor.getPlatform(),
      version: version,
      sideMenuToggled: false,
      ttlogo: require('@/assets/images/ttlogo.svg'),
      tacoLogo: require('@/assets/images/tacosLogo.png'),
      tacoT: require('@/assets/images/tacosT.png'),
      chinstrapLogo: require('@/assets/images/chinstrap.png'),
    }
  },
  mounted() {
    //this.$store.dispatch("sideMenu/sideMenuDetails")
    //this.$store.dispatch("sideMenu/menuRights")
    window.addEventListener('resize', function() {
      let bg = '#FFFFFF'
      if(window.innerWidth > 991) {
        bg = 'transparent'
      }
      if(document.querySelector('#navbar-main-collapse')) {
        document.querySelector('#navbar-main-collapse').style.backgroundColor = bg;
      }
    }, true);

    document.body.addEventListener('click', function(e){
      let x = e.clientX;
      let y = e.clientY;
      var elementMouseIsOver = document.elementFromPoint(x, y);
      if(elementMouseIsOver.className=="nav-link" || elementMouseIsOver.className=="navbar-toggler-icon"){
        //the menu
        if(elementMouseIsOver.className=="nav-link"){
          document.querySelector('#navbar-main-collapse').classList.remove('show')
        }
      } else if(document.querySelector('#navbar-main-collapse')){
        document.querySelector('#navbar-main-collapse').classList.remove('show')
      }
    }, true);

  },
  computed: {
    ...mapGetters({
      'userInfo': 'userAccess/user',
    }),
    uName(){
      return this.userInfo.username !== null ? this.userInfo.username : this.userInfo.first_name
    },
    canUserScan(){
      let match = []
      if(this.userInfo.permissions !== undefined) {
        match = this.userInfo.permissions.filter(function (item) {
          return item === process.env.VUE_APP_PERM_SCAN;
        });
      }
      return match.length > 0
    },
    canUserReports(){
      let match = []
      if(this.userInfo.permissions !== undefined) {
        match = this.userInfo.permissions.filter(function (item) {
          return item === process.env.VUE_APP_PERM_REPORT;
        });
      }
      return match.length > 0
    }
  },
  updated() {
    if(this.$route.query !== undefined && this.$route.query.scrollTo !== undefined){
      this.scrollToElement(this.$route.query.scrollTo)
    }
  },
  methods: {
    goTo(rl){
      this.$router.push(rl)
    },
    logout() {
      this.$store.dispatch('userAccess/logout').then(() => {
        this.$router.go('Login')

        //const redirectTo = {name: 'Login'}
        //this.$router.push(redirectTo)
      })
    },
    toggleSideMenu() {
      this.sideMenuToggled = !this.sideMenuToggled
    },
    scrollToElement(sel) {
      if(this.$route.name !== 'Home'){
        this.$router.push({name: 'Home',query: { scrollTo: sel }})
      }else {
        if (sel !== undefined && sel !== '' && document.querySelector(sel)) {
          document.querySelector(sel).scrollIntoView({
            behavior: "smooth", block: 'center'
          });
        }
      }
    },
  }
}
</script>
<style>
html {
  overflow-y: scroll;
}

.vfont {
  font-size:12px;
}

.taconav {
  background-color: #FCBF49;
}

.tacoLogo {
  width: 100%;
}

@media (max-width: 767.98px) {
  .tacoLogo {
    width: 100%;
    max-width: 200px;
  }
}

.tacotext {
  color: #950F14;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.tacotext li a {
  color: #950F14;
  text-decoration: none;
}



.collapse.show {
   background-color: #FFFFFF;
 }

</style>