<template>
  <div>
    <p class="bg-success white text-center" v-show="msg">
      {{msg}}
    </p>
    <div class="row mt-3 mb-3">
      <div class="col-lg-4 col-md-4">
      </div>
      <div class="col-lg-4 col-md-4">
        Forgot your password or didn't get your verification email? Enter your email below.
      </div>
    </div>
    <div class="row mt-3 mb-3">
      <div class="col-lg-4 col-md-4">
      </div>
      <div class="col-lg-4 col-md-4">
        <label for="exampleFormControlInput1" class="form-label">Email address</label>
        <input type="email" class="form-control" id="email" v-model="form.email" placeholder="name@example.com">
      </div>
    </div>
    <div class="row mt-3 mb-3">
      <div class="col-lg-4 col-md-4">
      </div>
      <div class="col-lg-4 col-md-4">
        <button class="btn btn-primary" @click="submit">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "login-problems",
  data() {
    return {
      fullPage: false,
      msg: '',
      form:{
        email : ''
      }
    }
  },
  methods: {
    submit() {
      let loader = this.$loading.show({
        // Optional parameters
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: true,
        onCancel: this.onCancel,
      });

      this.$store.dispatch('userAccess/forgotEmail', {email: this.form.email})
          .then(res => {
            if(res.forgot) {
              this.msg = 'Forgot password email sent.';
            }
            if(res.verified == 0) {
              this.msg = 'Verify email sent.';
            }
            loader.hide()
          })
          .catch(error => {
            console.log('🤷‍️' + error.message)
            loader.hide()
          })
    },
    onCancel() {
      console.log('User cancelled the loader.')
    }
  }
}
</script>

<style scoped>

</style>