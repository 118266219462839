<template>
  <div>
    <div>Sponsor Signup</div>
    <div v-if="UserInfo">

    </div>

    <form v-if="this.step == 1" @submit.prevent="submitForm">
      <div class="mb-3">
        <label for="first_name" class="form-label">First Name</label>
        <input type="text" @blur="v$.form.first_name.$touch" :class="{'border border-danger': v$.form.first_name.$error}" class="form-control" v-model="form.first_name" id="first_name">
      </div>
      <div class="mb-3">
        <label for="last_name" class="form-label">Last Name</label>
        <input type="text" @blur="v$.form.last_name.$touch" :class="{'border border-danger': v$.form.last_name.$error}" class="form-control" v-model="form.last_name" id="last_name">
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Email address</label>
        <input type="email" @blur="v$.form.email.$touch" :class="{'border border-danger': v$.form.email.$error}" class="form-control" v-model="form.email" id="email" aria-describedby="emailHelp">
        <p class="alert alert-danger p-1 mt-2" role="alert" v-if="v$.form.email.$error">Please enter a valid email.</p>
        <p class="alert alert-danger p-1 mt-2" role="alert" v-if="emailTaken">Email already used</p>
      </div>
      <div class="mb-3">
        <label for="email" class="form-label">Phone</label>
        <input type="text" @blur="v$.form.phone.$touch" :class="{'border border-danger': v$.form.phone.$error}" class="form-control" v-model="form.phone" id="email" aria-describedby="emailHelp">
        <p class="alert alert-danger p-1 mt-2" role="alert" v-if="v$.form.phone.$error">Please enter a cell phone number.</p>
      </div>
      <div class="mb-3">
        <label for="username" class="form-label">Username</label>
        <input type="text" @blur="v$.form.username.$touch" :class="{'border border-danger': v$.form.username.$error}" class="form-control" v-model="form.username" id="username">
        <p class="alert alert-danger p-1 mt-2" role="alert" v-if="usernameTaken">Username already used</p>
      </div>
      <div class="mb-3">
        <label for="signup-password">Create Password</label>
        <input v-model="form.password" @blur="v$.form.password.$touch" :class="{'border border-danger': v$.form.password.$error}" type="password" class="form-control" id="signup-password">
        <p class="alert alert-danger p-1 mt-2" role="alert" v-if="v$.form.password.$error">Enter a password with 5 characters or more.</p>
      </div>
      <div class="mb-3">
        <label for="signup-password-confirm">Confirm Password</label>
        <input v-model="form.passwordConfirm" @blur="v$.form.passwordConfirm.$touch" type="password" :class="{'border border-danger': v$.form.passwordConfirm.$error}" class="form-control" id="signup-password-confirm">
        <p class="alert alert-danger p-1 mt-2" role="alert" v-if="v$.form.passwordConfirm.$error">Please make sure passwords match.</p>
      </div>

      <button type="submit" class="btn btn-primary">Submit</button>
    </form>

    <div v-if="this.step == 2">
      What are you sponsoring?
      <button type="button" @click="nextStep(3)">Next</button>
    </div>

    <div v-if="this.step == 3">
      Payment
      <div class="wrapper" id="app">
        <CardForm
            @input-card-number="updateCardNumber"
            @input-card-name="updateCardName"
            @input-card-month="updateCardMonth"
            @input-card-year="updateCardYear"
            @input-card-cvv="updateCardCvv"
        />
        <!-- backgroundImage="https://images.unsplash.com/photo-1572336183013-960c3e1a0b54?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80" -->
      </div>

    </div>
  </div>
</template>

<script>
/**
 * Steps
 * 1. Register / If Registered already got to step 2
 * 2. Choose type of sponsor
 * 3. If Cash ask amount and show CC
 * 4. Something else
 */

import useVuelidate from '@vuelidate/core'
import {required, email, sameAs, minLength} from '@vuelidate/validators'
import CardForm from "@/components/Public/CardForm";
import { mapGetters } from 'vuex'

export default {
  name: "sponsorSignup",
  setup: () => ({v$: useVuelidate()}),
  components:{
    CardForm
  },
  data() {
    return {
      emailTaken: false,
      usernameTaken: false,
      isSignedUp : false,
      step: 1,
      form: {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        username: null,
        password: null,
        passwordConfirm: null,
        eventid: this.eventId
      },
      formData: {
        cardName: '',
        cardNumber: '',
        cardMonth: '',
        cardYear: '',
        cardCvv: ''
      }
    }
  },
  validations() {
    return {
      form: {
        username: {required},
        email: {required, email},
        phone: {required},
        password: {required, min: minLength(5)},
        passwordConfirm: {required, sameAsPassword: sameAs(this.form.password)},
        first_name: {required},
        last_name: {required}
      }
    }
  },
  computed: {
    ...mapGetters({
      'UserInfo': 'userAccess/user',
    }),
  },
  methods: {
    updateCardNumber (val) {
      this.formData.cardNumber = val
      console.log(val);
    },
    updateCardName (val) {
      console.log(val);
    },
    updateCardMonth (val) {
      console.log(val);
    },
    updateCardYear (val) {
      console.log(val);
    },
    updateCardCvv (val) {
      console.log(val);

    },
    nextStep(step){
      this.step = step
    },
    signUp(){
      this.$store.dispatch('signups/userSignup', this.eventId)
          .then(res => {
            console.log('res',res);
            if(res.added != undefined && res.added){
              this.isSignedUp = res.added
            }
          }).catch(error => {

        console.log('🤷‍️' + error.message)
      })
    },
    async submitForm() {
      console.log('ran me');
      const result = await this.v$.$validate()
      if (!result) {
        console.log('fail');
        return;
      } else {
        // if (this.form.username && this.form.password) {
        this.$store.dispatch('signups/sponsorSignup', this.form)
            .then(res => {
              console.log('res', res);
              this.emailTaken = false;
              this.usernameTaken = false;
              if(res.data.email){
                console.log('email taken');
                this.emailTaken = true;
              }

              if(res.data.username){
                console.log('username taken');
                this.usernameTaken = true;
              }

              if(res.data.signedup){
                this.isSignedUp = true
                this.step = 2
                localStorage.isSignedUp = true
                //this.$router.push({name: 'Login'})
              }
            })
            .catch(error => {
              //this.loading = false
              console.log('🤷‍️' + error.message)
            })
      }
    },
  },
  mounted() {
    if(this.UserInfo) {
      //route to sponsor section in user admin section if user has logged in once

    }

    if(localStorage.isSignedUp){
      this.step = 2
    }

  },
}
</script>

<style lang="scss">
@import '@/assets/styles.scss';
</style>