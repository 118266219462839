//import router from 'src/routes/routes'
import axios from 'axios'

export default {
    namespaced: true,
    state: {
        accessToken: '',
        user: {},
        activeUser: '',
        invalidLogin: true
    },
    getters: {
        accessToken(state) {
            // return localStorage.getItem('access_token') === null ? state.tokenLocal : localStorage.getItem('access_token')
            return localStorage.getItem('access_token') === null ? state.accessToken : localStorage.getItem('access_token')
            // return state.accessToken
        },
        user(state) {
            return localStorage.getItem('user') === null ? state.user : JSON.parse(localStorage.getItem('user'))
            //return state.user
        },
        getActiveUser(state) {
            return state.activeUser
        },
        invalidLogin(state) {
            return state.invalidLogin
        }
    },
    actions: {
        isAuthenticated({getters, commit}) {
            //Check with api that token is valid
            return new Promise((resolve, reject) => {
                let data = '';
                if (getters.accessToken === null || getters.accessToken === undefined) {
                    reject({error: '👾 no token'})
                }
                let token = getters.accessToken;
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/tokenLogin',
                    headers: {
                        'Authorization': token ? 'Bearer ' + token : ''
                    },
                    data: data
                };
                axios(config)
                    .then(function (response) {
                        if (response.data.error == 'Expired token' || response.data.error == 'Invalid Token') {
                            //send back to iat to get token or login
                            reject(response.data.error);
                        } else if (response.data.name == 'Error') {
                            reject(response.data.name);
                        } else if (response.data.first_name == undefined) {
                            reject({logout: true});
                        } else if (response.data.login) {
                            commit('setUser', response.data)
                            resolve(response.data)
                        } else {
                            reject({error: true})
                        }
                    })
                    .catch(function (error) {
                        if (!error.status) {
                            console.log('Error if', error)
                            //window.location.href = process.env.VUE_APP_ACHIEVE_SITE_LOGIN
                        } else {
                            reject(error);
                        }
                    });
            });
        },
        setLogin({commit}, data) {
            return new Promise((resolve) => {
                commit('setAccessToken', data.token);
                commit('setInvalidLogin', false);
                commit('setUser', data);
                resolve();
            });
        },
        login({commit}, {username, password}) {
            return new Promise((resolve, reject) => {
                let data = {username, password};
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/login',
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        if (response.data.token) {
                            commit('setAccessToken', response.data.token);
                            commit('setInvalidLogin', false)
                        } else {
                            commit('setInvalidLogin', true)
                        }
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.log('Error', error)
                        reject(error);
                    });
            })
        },
        login2({commit}, {username, password, signinType, otherLoginCreds}) {
            return new Promise((resolve, reject) => {
                let data = {username, password, signinType, otherLoginCreds};
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/login',
                    data: data
                };

                axios(config)
                    .then(function (response) {

                        if (response.data.token) {
                            commit('setAccessToken', response.data.token);
                            commit('setInvalidLogin', false)
                        } else {
                            commit('setInvalidLogin', true)
                        }
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.log('Error', error)
                        reject(error);
                    });
            })
        },
        resetPassword({state}, fdata) {
            return new Promise((resolve, reject) => {
                state.fdata = fdata
                let data = {...fdata}
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/site/resetPassword',
                    data: data
                }
                console.log(config);
                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        forgotEmailCheck({state}, forgotid) {
            return new Promise((resolve, reject) => {
                state.forgotid = forgotid
                let data = {forgotid}
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/site/forgotEmailCheck',
                    data: data
                }
                console.log(config);
                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        forgotEmail({state}, email) {
            return new Promise((resolve, reject) => {
                state.email = email
                let data = {...email}
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/site/forgotEmail',
                    data: data
                }
                console.log(config);
                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        verifyEmail({state}, vid) {
            return new Promise((resolve, reject) => {
                state.vid = vid
                let data = {vid}
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/site/verifyEmail',
                    data: data
                }
                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        logout({commit}) {
            return new Promise((resolve) => {
                //find a way to clear all vue states
                commit('setAccessToken', '')
                commit('setUser', '')
                //commit('sideMenu/setSideMenuOrdered', '')
                localStorage.clear()
                resolve()
            })
        },
        getActiveUser({commit}, {email}) {
            return new Promise((resolve, reject) => {
                let data = {email}
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/loginProblems/checkUserActive',
                    data: data
                }
                axios(config)
                    .then(response => {
                        commit('setActiveUser', response.data.data.active_account)
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        passwordReset({email}) {
            return new Promise((resolve, reject) => {
                let data = {email}
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/loginProblems/sendPasswordReset',
                    data: data
                }
                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        },
        otherLoginProblems({email, problem}) {
            return new Promise((resolve, reject) => {
                let data = {email, problem}
                let config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/loginProblems/sendLoginProblems',
                    data: data
                }
                axios(config)
                    .then(response => {
                        resolve(response.data)
                    })
                    .catch((error) => {
                        if (!error.status) {
                            console.log('Error if', error)
                        } else {
                            reject(error)
                        }
                    })
            })
        }
    },
    mutations: {
        setAccessToken(state, token) {
            localStorage.setItem('access_token', token)
            state.accessToken = token;
        },
        setUser(state, user) {
            localStorage.setItem('user', JSON.stringify(user))
            state.user = user;
        },
        setActiveUser(state, activeAccount) {
            state.activeUser = activeAccount
        },
        setInvalidLogin(state, invalidLogin) {
            state.invalidLogin = invalidLogin
        }
    }
}