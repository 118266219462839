import axios from 'axios'

export default {
    namespaced: true,
    state: {
        event: '',
        isScanning: false,
        events: [],
        allEvents: [],
        userEvents: [],
        usersCheckedIn: []
    },
    getters: {
        usersCheckedIn(state){
            return state.usersCheckedIn
        },
        listEvents(state){
            return state.events
        },
        listAllEvents(state){
            return state.allEvents
        },
        listUserEvents(state){
            return state.userEvents
        }
    },
    actions: {
        getAllEvents({commit}) {
            return new Promise((resolve, reject) => {
                let data = {};
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/events/listAllEvents',
                    headers: {
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        commit('setAllEvents',response.data.eventList);
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.log('Error', error)
                        reject(error);
                    });
            })
        },
        getUsersCheckin({commit}, eventid) {
            return new Promise((resolve, reject) => {
                let data = {eventid};
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/events/usersCheckedIn',
                    headers: {
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        commit('setUsersCheckedIn',response.data);
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.log('Error', error)
                        reject(error);
                    });
            })
        },
        getEvents({commit}) {
            return new Promise((resolve, reject) => {
                let data = {};
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/site/listEvents',
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        commit('setEvents',response.data.eventList);
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.log('Error', error)
                        reject(error);
                    });
            })
        },
        signEventWaiver(_,dataObj) {
            return new Promise((resolve, reject) => {
                let data = {...dataObj};
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/events/signWaiver',
                    headers: {
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        unregisterEvent({dispatch},eventid){
            return new Promise((resolve, reject) => {
                let data = {eventid};
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/events/eventUnregister',
                    headers: {
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        dispatch('getUserEvents');
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.log('Error', error)
                        reject(error);
                    });
            })
        },
        getUserEvents({commit}) {
            return new Promise((resolve, reject) => {
                let data = {};
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/events/userEvents',
                    headers: {
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        commit('setUserEvents',response.data.userEvents);
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.log('Error', error)
                        reject(error);
                    });
            })
        },
        markEventAttendedNoDataSet({state,commit},eventid) {
            return new Promise((resolve, reject) => {
                if(!state.isScanning){
                    console.log('✅ update attendance');
                }else{
                    reject({'msg':'Already Running'})
                    return false;
                }

                commit('setIsScanning',true)
                let data = {eventid};
                state.event = eventid
                data.type = 'guest';
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/markAttendEvent',
                    headers: {
                        'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        reject(error);
                    });
            })
        },
        markEventAttended({state,commit},{eventid, userid}) {
            return new Promise((resolve, reject) => {
                if(!state.isScanning){
                    console.log('✅ update attendance');
                }else{
                    console.log('🛑 already running attendance');
                    reject({'msg':'Already Running'})
                    return false;
                }

                commit('setIsScanning',true)
                let data = {eventid, userid};
                state.event = eventid
                data.type = 'guest';
                var config = {
                    method: 'post',
                    url: process.env.VUE_APP_API_SERVER + '/events/markAttendEvent',
                    headers: {
                      'Authorization': 'Bearer ' + this.getters['userAccess/accessToken']
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        //let key = Object.keys(state.userEvents).find(key => state.userEvents[key].id === eventid);
                        //state.userEvents[key].attended = true
                        //commit('setUserEvents',state.userEvents);

                        setTimeout(function(){
                            commit('setIsScanning',false)
                        },2000);

                        //state.isScanning = false;
                        resolve(response.data)
                    })
                    .catch(function (error) {
                        console.log('Error', error)
                        commit('setIsScanning',false)
                        //state.isScanning = false;
                        reject(error);
                    });
            })
        },
    },
    mutations:{
        setUsersCheckedIn(state, events) {
            state.usersCheckedIn = events
        },
        setEvents(state, events) {
            state.events = events
        },
        setAllEvents(state, events) {
            state.allEvents = events
        },
        setUserEvents(state, events) {
            //order
            let newDates = []
            if(events != undefined) {
                let dates = []
                let keys = []
                for (const [key, value] of Object.entries(events)) {
                    keys.push(key)
                    dates.push(value)
                }

                newDates = dates.sort(function (a, b) {
                    return new Date(b.date_end) - new Date(a.date_end);
                });
            }
            state.userEvents = newDates
        },
        setIsScanning(state, scan) {
            state.isScanning = scan
        },
    }
}