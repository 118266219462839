import {createRouter, createWebHashHistory} from 'vue-router'
import Login from '../components/Login'
import DashboardPage from "@/components/Dashboard/DashboardPage";
import NotFoundPage from "@/components/NotFoundPage";
import Home from "@/components/Public/Home";
import ScanEvent from "@/components/Qrcode/ScanEvent";
import eventAttended from "@/components/Qrcode/eventAttended";
import GuestSignup from "@/components/Public/guestSignup";
import myEvents from "@/components/Dashboard/myEvents";
import sponsorSignup from "@/components/Public/sponsorSignup";
import eVerify from "@/components/Public/eVerify";
import loginProblems from "@/components/Public/login-problems";
import forgot from "@/components/Public/forgot";
import signWaiver from "@/components/Dashboard/signWaiver";
import eventTicket from "@/components/Dashboard/eventTicket";
import store from "@/store";
import checkInUser from "@/components/Dashboard/checkInUser";
import Reports from "@/components/Dashboard/Reports";
import EventDetails from "@/components/Public/EventDetails";

const routes = [
    {
        path: '/EventDetails',
        name: 'EventDetails',
        props: true,
        component: EventDetails
    },
    {
        path: '/',
        name: 'Home',
        props: true,
        component: Home
    },
    {
        path: '/login/:eventid?',
        name: 'Login',
        component: Login,
        props: true

    },
    {
        path: '/guestSignup/:eventId',
        name: 'guestSignup',
        props: true,
        component: GuestSignup
    },
    {
        path: '/eVerify/:vid',
        name: 'eVerify',
        props: true,
        component: eVerify
    },
    {
        path: '/scanEvent',
        name: 'scanEvent',
        component: ScanEvent,
        meta: {requiresAuth: true},
    },
    {
        path: '/signWaiver',
        name: 'signWaiver',
        component: signWaiver,
        props: true,
        meta: {requiresAuth: true},
    },
    {
        path: '/eventTicket/:eventId?',
        name: 'eventTicket',
        component: eventTicket,
        meta: {requiresAuth: true},
    },
    {
        path: '/sponsorSignup',
        name: 'sponsorSignup',
        component: sponsorSignup,
    },
    {
        path: '/checkInUser',
        name: 'checkInUser',
        component: checkInUser,
        meta: {requiresAuth: true, permissions: [process.env.VUE_APP_PERM_SCAN]},
    },
    {
        path: '/login-problem',
        name: 'loginProblem',
        component: loginProblems,
    },
    {
        path: '/forgot/:forgotid',
        name: 'forgot',
        props: true,
        component: forgot,
    },
    {
        path: '/myEvent',
        name: 'myEvent',
        component: myEvents,
        props: true,
        meta: {requiresAuth: true},
    },
    {
        path: '/eventAttended',
        name: 'eventAttended',
        component: eventAttended,
        meta: {requiresAuth: true},
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardPage,
        meta: {requiresAuth: true},
    },
    {
        path: '/reports',
        name: 'reports',
        component: Reports,
        props: true,
        meta: {requiresAuth: true, permissions: [process.env.VUE_APP_PERM_REPORT]},
    },
    {
        // path: "*",
        path: "/:catchAll(.*)",
        name: "NotFound",
        component: NotFoundPage,
        meta: {
            requiresAuth: false
        }
    }
]
//history: createWebHashHistory(),
const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to) {
        // always scroll to top
        if (to.name !== 'Home') {
            return {top: 0}
        }
    },
})

router.beforeEach((to, from, next) => {
    document.title = 'Tacos Together'
    if (from.name === 'scanEvent') {
        store.dispatch('utils/stopAllAudioVideo')
    }

    //if (to.name === 'Login' || to.name === 'Home' || to.name === 'guestSignup' || to.name === 'NotFound' || to.name === 'sponsorSignup') {
    if (!to.meta.requiresAuth) {
        next()
    } else {
        store.dispatch('userAccess/isAuthenticated')
            .then(user => {

                //store redirect in localstorage
                /*
                var rdt = localStorage.getItem('redirectTo');
                if(rdt !== undefined && rdt !== null){
                  console.log('then 2');
                  localStorage.removeItem('redirectTo')
                  router.push(rdt)
                }else {

                */
                if (to.matched.some(route => route.meta.requiresAuth)) {
                    if (to.meta.permissions !== undefined) {
                        let match = to.meta.permissions.filter(function (item) {
                            return user.permissions.includes(item);
                        });
                        if (match.length <= 0) {
                            next({name: 'Login'})
                        } else {
                            if (user) {
                                next()
                            } else {
                                next({name: 'Login'})
                            }
                        }
                    } else {

                        if (user) {
                            next()
                        } else {
                            next({name: 'Login'})
                        }
                    }
                } else {
                    if (user) {
                        next()
                    } else {
                        next()
                    }
                }
                //}
            }).catch(err => {
            if (err.logout) {
                store.dispatch('userAccess/logout').then(() => {
                    next({name: 'Login'})
                })
            } else {
                //store redirect in session variable
                if (window.location.pathname != '' && window.location.pathname != '/login') {
                    //localStorage.setItem('redirectTo', window.location.pathname)
                }
                next({name: 'Login'})
            }
        })
    }
})

export default router
